import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const StaffApplication = ({ data }) => (
  <Layout>
    <SEO title="Staff Application" />
    <div id="main">
      <div style={{ backgroundColor: "#FFF" }}>
        <a href="/">
          <img
            id="logo"
            src={data.file.publicURL}
            alt="Enosh Bible Camp Logo"
          />
        </a>
      </div>
      <div className="diagonal">
        <svg viewBox="0 0 100 10" preserveAspectRatio="none">
          <polygon points="100,0 0,10 0,0" style={{ fill: "#FFF" }} />
        </svg>
        <Link className="button--anchor" to="/">
          Return To Home
        </Link>
      </div>
      <div className="info">
        <iframe
          title="Enosh Bible Camp Staff Application Form"
          src="https://docs.google.com/forms/d/e/1FAIpQLSfRC4zKQ4PXsYEURVp6Cy_GZ0fylZ1IB0lMxv443JgIAnoeWw/viewform?embedded=true"
          width="100%"
          height="3000"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading...
        </iframe>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "campenoshlogomini_web.svg" }) {
      publicURL
    }
  }
`

export default StaffApplication
